import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {IRPStore} from "../../../app/rp_reducer";

export enum Layer {
    Price = "price",
    TransportLines = "transportLines"
}

export type IMapLayersStore = {
    [key in Layer]: boolean;
};

const initialState: IMapLayersStore = {
    price: false,
    transportLines: false
};

export const mapLayersSlice = createSlice({
    name: "layers",
    initialState,
    reducers: {
        setMapLayers: (state, action: PayloadAction<Partial<{[key in Layer]: boolean}>>) => ({
            ...state,
            ...action.payload
        })
    }
});

export const {setMapLayers} = mapLayersSlice.actions;

export const selectLayer = (state: IRPStore) => state.offerListMap.layers;
