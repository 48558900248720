import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IVendorOfferListResponse} from "../../offer/types/VendorOffersListResponse";
import {getCountriesList} from "../../region/utils/get_countries_list";

const FETCH_VENDOR_DETAIL_OFFERS = "desktop_vendor_detail/FETCH_VENDOR_DETAIL_OFFERS";
export const fetchVendorDetailOffersTypes = createRequestActionTypes(FETCH_VENDOR_DETAIL_OFFERS);

const FETCH_VENDOR_DETAIL_OFFERS_BY_REGION = "desktop_vendor_detail/FETCH_VENDOR_DETAIL_OFFERS_BY_REGION";
export const fetchVendorDetailOffersByRegionTypes = createRequestActionTypes(FETCH_VENDOR_DETAIL_OFFERS_BY_REGION);

const fetchVendorDetailOffers =
    (ctx: IFetchContext<IRPRequestMeta> | null, query: Record<string, number>, actionType: RequestActionTypes) => (dispatch: Dispatch) => {
        dispatch({type: actionType.start});

        const offerListApiLink = apiV2ListLink.offer.list(Scenario.VENDOR_DETAIL_OFFER_LIST);

        const url = appendQueryString(offerListApiLink, {
            ...query,
            page_size: 1000,
            page: 1,
            country: getCountriesList()
        });

        return getRequest(ctx ? ctx.meta : {}, url).then(async (json: IVendorOfferListResponse) => {
            // prepare result
            const result = {
                offers: json.results,
                count: json.count,
                page: json.page
            };

            dispatch({type: actionType.success, result});
            return json;
        });
    };

export const fetchVendorDetailOffersAtRoute = (ctx: IFetchContext<IRPRequestMeta>) =>
    fetchVendorDetailOffers(ctx, {vendor: ctx.match.params.vendorId}, fetchVendorDetailOffersTypes);

export const fetchVendorDetailOffersByRegion = (vendorId: number, regionId: number | null) =>
    fetchVendorDetailOffers(null, {vendor: vendorId, ...(regionId == null ? {} : {region: regionId})}, fetchVendorDetailOffersByRegionTypes);
