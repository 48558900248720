/* eslint-disable @typescript-eslint/no-explicit-any */
import type {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {createFormActions, createFormActionTypes, fromQueryValues, IFormValuesAction} from "@pg-mono/form";
import {isPlainObject} from "@pg-mono/nodash";
import {parseSearch} from "@pg-mono/request";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {resetFetchSelectedRegions} from "../../offer/list/actions/offer_list_selected_data_actions";
import {searchFormFields} from "../constants/search_form_constants";
import {fetchSearchRegions, prepareSearchValue} from "../utils/prepare_inital_multi_search_form_values";

const Multi_query_search_actions = "offer/MULTI_QUERY_SEARCH_FORM";
export const searchFormTypes = createFormActionTypes(Multi_query_search_actions);
export const searchFormActions = createFormActions<Record<keyof typeof searchFormFields, any>>(searchFormTypes);

type FormValuesType = Record<keyof typeof searchFormFields, any>;

/**
 * Components actions
 */

export const resetSearchForm = (query: Record<string, string>) => async (dispatch: Dispatch<IFormValuesAction<unknown>>, getState: () => IRPStore) => {
    // prepare all fields based on query params
    const formValues: FormValuesType = fromQueryValues(searchFormFields, query);
    const offerList = getState().offerList;
    // Search engine is being used outside of offer list where provided data is limited.
    if (!offerList) {
        return dispatch(searchFormActions.replace({...formValues}));
    }
    const {selectedRegions} = getState().offerList;
    //set formValues.construction_end_date for filters on "gotowe " listings
    if (offerList.list.latestQuery?.construction_end_date === "0") {
        formValues.construction_end_date = [+offerList.list.latestQuery?.construction_end_date];
    }
    // prepare location field values based on query params
    const mainFormValues = await prepareSearchValue(query, selectedRegions);
    // update form values
    const initialFormValues = {
        ...formValues,
        ...(mainFormValues ? mainFormValues : {}) // main priority - special case fields
    };
    dispatch(searchFormActions.replace(initialFormValues));
};

export const resetMultiRegionSearchForm = (query: Record<any, any>, locationSearch: string) => async (dispatch: Dispatch<IFormValuesAction<unknown>>) => {
    // prepare all fields based on query params
    const formValues: FormValuesType = fromQueryValues(searchFormFields, query);
    // Search engine is being used outside of offer list where provided data is limited.
    const parsedSearchQuery = parseSearch(locationSearch);
    const searchFieldQuery = parsedSearchQuery.region ? await fetchSearchRegions(parsedSearchQuery.region) : {};

    // update form values
    const initialFormValues = {
        ...formValues,
        ...searchFieldQuery // main priority - special case fields
    };
    dispatch(searchFormActions.replace(initialFormValues));
};

/**
 * Data fetcher actions
 */

// considers `prevActionResult` because of friendly-url
export const resetClientOnlySearchFormAtRoute =
    ({route, prevRoute, prevResult}: IFetchContext<IRPRequestMeta>) =>
    async (dispatch: Dispatch): Promise<boolean> => {
        if (prevRoute == null) {
            // server side we do not reset form, because `OfferListMapSearchEngine` mount lifecycle does this instead
            return true;
        }
        const query = isPlainObject(prevResult) ? prevResult : prevResult[0] || route.query;
        !query.region && (await dispatch(resetFetchSelectedRegions())); // we do not want selectedData if there is no region (empty regions)
        await dispatch(resetSearchForm(query));
        return true;
    };

// considers `prevActionResult` because of friendly-url
export const resetClientOnlyMultiRegionSearchFormAtRoute =
    ({route, prevRoute, prevResult}: IFetchContext<IRPRequestMeta>) =>
    async (dispatch: Dispatch): Promise<boolean> => {
        if (prevRoute == null) {
            // server side we do not reset form, because `OfferListMapSearchEngine` mount lifecycle does this instead
            return prevResult;
        }

        await dispatch(resetMultiRegionSearchForm(route.query, window.location.search));
        return prevResult;
    };
