import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {convertToCountryLatLngLiteral} from "@pg-mono/open-street-map";

import {clientRpRequestMeta} from "../../../../client/client_rp_request_meta";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {fetchDetailPoi} from "../../../maps/actions/fetch_detail_poi";
import {Country} from "../../../region/types/Country";

export const fetchOfferDetailPoiAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const {offer} = ctx.prevResult;

    const {lat, lng} = convertToCountryLatLngLiteral(offer.geo_point.coordinates, offer.region.country);

    return dispatch(
        fetchDetailPoi(ctx.meta, {
            radius: 3000,
            latitude: lat,
            longitude: lng
        })
    );
};

export const fetchPropertyDetailPoiAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const {offer} = ctx.prevResult;

    const {lat, lng} = convertToCountryLatLngLiteral(offer.geo_point.coordinates, offer.region.country);

    return dispatch(
        fetchDetailPoi(ctx.meta, {
            radius: 3000,
            latitude: lat,
            longitude: lng
        })
    );
};

export const fetchPropertyDetailPoi = (coordinates: [number, number], country: Country) => async (dispatch: Dispatch) => {
    const {lat, lng} = convertToCountryLatLngLiteral(coordinates, country);

    return dispatch(
        fetchDetailPoi(clientRpRequestMeta, {
            radius: 3000,
            latitude: lat,
            longitude: lng
        })
    );
};
